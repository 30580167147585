import logo from '../img/special-pack/logo.png';

const SPPHeader = () => {
    return (
        <header id="sppheader">
            <a id="top"></a>
            <div>
                <a href="https://travel.jorudan.co.jp/" rel="noreferrer noopener">
                    <img src={logo} alt="ジョルダンMaaSパッケージ" />
                </a>
            </div>
        </header>
    );
}

export default SPPHeader;