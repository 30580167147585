import logo from '../img/special-pack/logo.png';
import SPP from '../css/special-pack2.module.css';

const SPPFooter = () => {
    return (
        <footer id="sppfooter">
            <div className={SPP.footLink}>
               <ul>
                 <li>
                     <a href="https://travel.jorudan.co.jp/info/terms" rel="noreferrer noopener">
                        規約
                     </a>
                 </li>
                 <li>
                     <a href="https://travel.jorudan.co.jp/info/conditions/keiyaku_bosyu" rel="noreferrer noopener">
                        約款・条件書
                     </a>
                 </li>
                 <li>
                     <a href="https://travel.jorudan.co.jp/info/charge" rel="noreferrer noopener">
                        旅行業取扱料金表
                     </a>
                 </li>   
                 <li>
                     <a href="https://travel.jorudan.co.jp/info/company" rel="noreferrer noopener">
                        会社案内
                     </a>
                 </li>
                 <li>
                     <a href="https://travel.jorudan.co.jp/info/privacy" rel="noreferrer noopener">
                        プライバシーポリシー
                     </a>
                 </li>
             </ul>
            </div>
            <div className={SPP.footInfo}>
              <a href="https://travel.jorudan.co.jp/" rel="noreferrer noopener">
                  <img src={logo} alt="ジョルダントラベル" />
              </a>
              <p>
                観光庁長官登録旅行業第1678号<br/>
                一般社団法人日本旅行業協会（ＪＡＴＡ）正会員 ／ ＩＡＴＡ公認代理店
              </p>
              <p>  
                Copyright &copy; 1996-<span id="copyYear"></span>
                Jorudan Co.,Ltd. All Rights Reserved. 
              </p>
            </div>
        </footer>
    );
}

export default SPPFooter;
