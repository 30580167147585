import { FC } from 'react';

import SPP from '../../css/special-pack2.module.css';
import title_shinkansen from '../../img/special-pack/title_shinkansen.png';

type Props = {
    froms?: string[];
    direction?: string;
};

const DirectionsHeader: FC<Props> = (props) => {
    const {froms, direction} = props;
    const clsSH = (() => {
        switch(direction) {
            case '関西方面': return `${SPP.pageTitle} ${SPP.specialHead} ${SPP.SHKansai}`;
            case '中部方面': return `${SPP.pageTitle} ${SPP.specialHead} ${SPP.SHChubu}`;

            case '東北方面': return `${SPP.pageTitle} ${SPP.specialHead} ${SPP.SHTohoku}`;
                     
            case '北陸方面': return `${SPP.pageTitle} ${SPP.specialHead} ${SPP.SHHokuriku}`;
            case '信州方面': return `${SPP.pageTitle} ${SPP.specialHead} ${SPP.SHShinshu}`;

            default: return '';
        }
    })();
    
    return (
        <>
            <section className={SPP.specialFull}>
                <div className={clsSH}>
                    <img src={title_shinkansen} alt="早期割引！5日前までのお申込みがお得！新幹線往復日帰りパック" />
                </div>
                <div className={SPP.areaTitle}>
                    <p>{froms?.join('・')} &#8594; {direction}</p>
                </div>
            </section>

            <section className={`${SPP.specialFull} ${SPP.pageDescript}`}>
                <div className={SPP.sfMain}>
                    <p>観光にもビジネスにも!</p>
                    <p>早期お申込みで新幹線のご利用が超お得に!</p>
                </div>
                <div className={SPP.sfText}>
                    <p>{froms?.join('/')}発・{direction}着の新幹線をご利用なら、ジョルダンMaaSパッケージの日帰りパックがだんぜん便利！出発日の5日前までにお申込みを完了すれば、<span className={SPP.imp}>超お得価格でのご案内</span>が可能です♪</p>
                    <p>気になるパックがありましたら「お問い合わせ」ボタンからお気軽にお問い合せください。「ジョルダントラベル」の旅行専門スタッフが、お客様のご要望に合わせた最適なプランをご提案します！</p>
                </div>
            </section>
        </>
    );
}

export default DirectionsHeader;