import * as React from "react";
import { Routes, Route, useLocation, Outlet } from "react-router-dom";
import { fakeAuthProvider } from "./auth";
import * as xml from "xmlbuilder2";
import { Dispatch, SetStateAction, useEffect } from "react";

import Header from "./components/Header";
import Footer from "./components/Footer";

import MyPage from "./pages/MyPage";

import Top from "./pages/Top";

import Order from "./pages/Order";
import Select from "./pages/Order/Select";
import Confirm from "./pages/Order/Confirm";
import Final from "./pages/Order/Final";
import Complete from "./pages/Order/Complete";

import OrderFree from "./pages/OrderFree";
import SelectFree from "./pages/OrderFree/SelectFree";
import ConfirmFree from "./pages/OrderFree/ConfirmFree";
import FinalFree from "./pages/OrderFree/FinalFree";
import CompleteFree from "./pages/OrderFree/CompleteFree";

import Info from "./pages/Info";
import Terms from "./pages/Info/Terms";
import Privacy from "./pages/Info/Privacy";
import Charge from "./pages/Info/Charge";
import Company from "./pages/Info/Company";
import InputInfo from "./pages/Order/InputInfo";
import { Conditions, Condition } from "./pages/Info/Conditions";
import Help from "./pages/Info/Help";
import Guide from "./pages/Info/Guide";

import Home from './pages/shinkansen/Home';
import Air from './pages/air/Home';
import Shinkansen from './pages/shinkansen/Directions';

import MainHeadBlock from './components/MainHelmet';

import { COOKIE_EID, JID_URL, BASE_URL, JID_ACCOUNT_URL } from "./constants";

import ReactGA from "react-ga4";
import { useCookies } from "react-cookie";
import NotFound from "./pages/NotFound";

ReactGA.initialize("UA-632281-1");
ReactGA.send("pageview");

export default function App() {
  return (
    <>
      <AuthProvider>
        <Routes>
          <Route element={<Layout />}>
            <Route index element={<Top />} />
            <Route path="order" element={<Order />}>
              <Route path="select" element={<Select />} />
              <Route path="confirm" element={<Confirm />} />
              <Route
                path="inputInfo"
                element={
                  <RequireAuth>
                    <InputInfo />
                  </RequireAuth>
                }
              />
              <Route path="final" element={<Final />} />
              <Route path="complete" element={<Complete />} />
            </Route>

            <Route path="orderFree" element={<OrderFree />}>
              <Route path="select" element={<SelectFree />} />
              <Route path="confirm" element={<ConfirmFree />} />
              <Route
                path="inputInfo"
                element={
                  <RequireAuth>
                    <InputInfo />
                  </RequireAuth>
                }
              />
              <Route path="final" element={<FinalFree />} />
              <Route path="complete" element={<CompleteFree />} />
            </Route>

            <Route path="info" element={<Info />}>
              <Route path="terms" element={<Terms />} />
              <Route path="conditions" element={<Conditions />}>
                <Route path=":conditionId" element={<Condition />} />
              </Route>
              <Route path="charge" element={<Charge />} />
              <Route path="company" element={<Company />} />
              <Route path="privacy" element={<Privacy />} />

              <Route path="help" element={<Help />} />
              <Route path="guide" element={<Guide />} />
            </Route>

            <Route
              path="mypage"
              // path="userid/mypage"
              element={
                <RequireAuth>
                  <MyPage />
                </RequireAuth>
              }
            />

            <Route
              path="inputInfo"
              element={
                <div className="w-full max-w-7xl mx-auto my-5 px-2 md:px-6">
                  <InputInfo />
                </div>
              }
            />

            

            <Route path="*" element={<NotFound />} />
          </Route>


          <Route path="special-pack">
            <Route path="air_haneda" element={<Air from="haneda" />} />

            <Route path="shinkansen_home" element={<Home />} />

            <Route path="shinkansen_tokyo-kansai" element={<Shinkansen direction="tokyo-kansai" />} />
            <Route path="shinkansen_tokyo-tohoku" element={<Shinkansen direction="tokyo-tohoku" />} />
            <Route path="shinkansen_tokyo-chubu" element={<Shinkansen direction="tokyo-chubu"/>} />
            <Route path="shinkansen_tokyo-hokuriku" element={<Shinkansen direction="tokyo-hokuriku"/>} />
            <Route path="shinkansen_tokyo-shinshu" element={<Shinkansen direction="tokyo-shinshu"/>} />
          </Route>

        </Routes>
      </AuthProvider>

      <Routes></Routes>
    </>
  );
}

function Layout() {
  return (
    <>
      <MainHeadBlock />
      
      <div className="w-full max-w-screen-2xl mx-auto flex flex-col min-h-screen">
        <Header />
        <Outlet />
        <Footer />
      </div>
    </>
  );
}

interface AuthContextType {
  user: any;
  signin: (
    email: string,
    password: string,
    callback: VoidFunction
  ) => Promise<void>;
  signout: (callback: VoidFunction) => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);

function jsonDateReviver(key: string, value: string) {
  // matches 2019-06-20T12:29:43.288Z (with milliseconds) and 2019-06-20T12:29:43Z (without milliseconds)
  var dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,}|)Z$/;

  if (typeof value === "string" && dateFormat.test(value)) {
    return new Date(value);
  }

  return value;
}

function getStorageValue<T>(key: string, defaultValue: T): T {
  // getting stored value
  const saved = localStorage.getItem(key);
  return saved ? JSON.parse(saved, jsonDateReviver) : defaultValue;
}

type SetValue<T> = Dispatch<SetStateAction<T>>;

export function useLocalStorage<T>(
  key: string,
  defaultValue: T
): [T, SetValue<T>] {
  const [value, setValue] = React.useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value, defaultValue]);

  return [value, setValue];
}

function AuthProvider({ children }: { children: React.ReactNode }) {
  let [user, setUser] = useLocalStorage<any>("eid", null);

  let [cookies, setCookie, removeCookie] = useCookies([COOKIE_EID]);
  let location = useLocation();

  // user is not logged in
  if (!user) {
    // but is logged in elsewhere
    if (cookies[COOKIE_EID]) {
      setUser({ eid: cookies[COOKIE_EID] });
    } else {
      // First check for an Eid parameter
      let params = new URLSearchParams(location.search);
      let eid = params.get("Eid");

      if (eid) {
        // auth.signin()
        setUser({ eid });

        setCookie(COOKIE_EID, eid, { domain: ".jorudan.co.jp", path: "/" });

        params.delete("Eid");
        window.history.replaceState({}, "", `${location.pathname}?${params}`);
      }
    }
  }

  let signin = async (
    email: string,
    password: string,
    callback: VoidFunction
  ) => {
    // signin
  };

  // CORS HTTP header
  // Access-Control-Allow-Origin: *
  // Access-Control-Allow-Methods: GET, POST, PUT, DELETE, OPTIONS

  let signout = (callback: VoidFunction) => {
    return fakeAuthProvider.signout(() => {
      localStorage.removeItem("eid");
      localStorage.removeItem("order");
      removeCookie(COOKIE_EID, { domain: ".jorudan.co.jp", path: "/" });
      setUser(null);
      //callback();
      window.location.assign(`${JID_ACCOUNT_URL?.split('?')[0]}fa/logout.html?srvid=jtravel&returl=${BASE_URL}`);
    });
  };

  let value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user) {
    // First check for an Eid parameter
    let params = new URLSearchParams(location.search);

    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.

    const url = new URL(`${JID_URL}login.cgi`);
    params = new URLSearchParams(url.search);
    params.set("serviceid", "jtravel");
    params.set("NextUrl", window.location.toString());

    window.location.assign(`${url}?${params}`);

    return <></>;
  }

  return children;
}
