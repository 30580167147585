import React from 'react';
import { FC, useState, useEffect, ReactNode } from 'react';

import axis from 'axios';
import parse from 'html-react-parser';
import qs from 'qs';

import ShinkansenHeadBlock from '../../components/shinkansen/Helmet';
import DirectionsHeader from '../../components/shinkansen/DirectionsHeader';
import SPPHeader from '../../components/SPPHeader';
import SPPBackTop from '../../components/SPPBackTop2';
import SPPFooter from '../../components/SPPFooter2';

import SPP from '../../css/special-pack2.module.css';

import { SpecialPackShinkansen } from '../../interfaces';

type Props = {
    direction?: string;
};

const Shinkansen: FC<Props> = (props) => {
    const { direction } = props;
    const [datas, setDatas] = useState([] as SpecialPackShinkansen[]);
    const [froms, setFroms] = useState([] as string[]);
    //const [directions, setDierections] = useState([] as string[]);
    const [directionName, setDirectionName] =  useState('');

    useEffect(() => {
        // ページビューのトラッキング
        window.gtag('config', 'YOUR_TRACKING_ID', {
          page_path: window.location.pathname,
        });
    }, []);

    useEffect(() => {
        const getData = () => {
            //const bufDirections: string[] = [];
            const apiUrl = `${process.env.REACT_APP_STRAPI_URL}/special-pack-shinkansens`;
            const p = {
                populate: 'special_pack_shinkansen_direction',
                filters: {
                    special_pack_shinkansen_direction: {
                        key: {
                            $eq: direction
                        }
                    }
                },
                sort: [
                    'SortOrder:asc'
                ]
            }

            const headers = {
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_STRAPI_KEY}`
                }
            };

            const bufSampleTypes: SpecialPackShinkansen[] = [];
            const bufFroms: string[] = [];
            let bufDirection = '';
            
            axis.get(`${apiUrl}?${qs.stringify(p)}`, headers)
                .then((res) => { 
                    res.data.data.forEach((resData: any) => {
                        const data: SpecialPackShinkansen = resData.attributes;

                        bufDirection = resData.attributes.special_pack_shinkansen_direction.data.attributes.name
                        data.Direction = bufDirection;

                        if(bufFroms.indexOf(data.Area) < 0) {
                            bufFroms.push(data.Area)
                        }

                        bufSampleTypes.push(data);
                    });

                    setDatas(bufSampleTypes);
                    setFroms(bufFroms);
                    setDirectionName(bufDirection);
                })
                .catch((error) => {
                    console.log(error);
            });
        };
    
        getData();
    }, []);
    
    return (
        <>
            <ShinkansenHeadBlock from={froms[0]} direction={directionName} />

            <SPPHeader />

            <DirectionsHeader froms={froms} direction={directionName} />

            {
                froms.map((v, i) => {
                    const targets = datas.filter(x => x.Area === v);
                    
                    return (
                        <section key={i}>
                            { targets.length > 0 ? createArticle(targets, directionName) :'' }
                        </section>
                    );
                })
            }

            <div className={SPP.jumpHome}>
                <a href="/special-pack/shinkansen_home">他の方面も見てみる</a>
            </div>
            
            <SPPBackTop />

            <SPPFooter />
        </>
    );
}

function createArticle(targets: SpecialPackShinkansen[], direction: string): ReactNode {
    const title = targets[0].Area;
    const label_1 = targets[0].Label_Normal;
    const label_2 = targets[0].Label_Special;
    const description = targets[0].Description;
    const targetUrl = targets[0].Url.replace(/&entry.+/,'');
    const targetUrl2 = encodeURIComponent(targetUrl);

    console.log(targets)

    return (
        <article className={SPP.tableWrap} id={direction} key={`${targets[0].Area}_1`}>
            <div className={SPP.tableWrapTitle}>
                <p>{parse(title)} 発</p>
            </div>

            <div className={SPP.tableWrapContent}>
                <table>
                    <thead>
                        <tr key={`${targets[0].Area}_2`}>
                            <th colSpan={2}>駅名</th>
                            <th>{parse(label_1)}</th>
                            <th>{parse(label_2)}</th>
                            <th className={SPP.enOtokuG}>最大割引金額</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            targets.map((v, i) => {
                                return (
                                    <React.Fragment key={`${v.Area}_3_${i}`}>
                                        <tr key={`${v.Area}_3_${i}_1`}>
                                            <td rowSpan={2} className={SPP.areaName}>{v.Destination}</td>
                                            <td className={SPP.routeName}>片道</td>
                                            <td className={SPP.en}>{v.Price_Normal_OneWay.toLocaleString()}</td>
                                            <td></td>
                                            <td className={SPP.enOtokuG}></td>
                                        </tr>

                                        <tr className={SPP.listDash} key={`${v.Area}_3_${i}_2`}>
                                            <td className={SPP.routeName}>往復</td>
                                            <td className={SPP.en}>{v.Price_Normal_RoundTrip.toLocaleString()}</td>
                                            <td className={`${SPP.en} ${SPP.pack}`}>{v.Price_Special_RoundTrip.toLocaleString()}</td>
                                            <td className={`${SPP.enOtoku} ${SPP.enOtokuG}`}><p>{v.Discount_Normal_RoundTrip.toLocaleString()}</p></td>
                                        </tr>
                                    </React.Fragment>
                                );
                            })
                        }

                        <tr className={SPP.toiawaseWrap} key={`${targets[0].Area}_4`}>
                            <td colSpan={6}>
                                <div className={SPP.cautionWrap}>
                                    <p>
                                    {parse(description)}
                                    </p>
                                </div>
                                <div>
                                    <p>{`${targets[0].Area}発・${direction}着`}の<span>往復パックを利用する</span></p>
                                    <a
                                        href={targetUrl}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        title="お問合せボタン"
                                        onClick={
                                            () => {
                                                window.gtag('event', 'click', {'event_category': 'link', 'event_label': targetUrl2, 'value': '1'});
                                            }
                                        }
                                    >
                                        お問い合わせ
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </article>
    );
}

export default Shinkansen;
