import SPP from '../css/special-pack2.module.css';

const SppBacktop = () => {
    return (
        <div id="sppbacktop">
            <div className={SPP.backtop}>
                <a href="#top">
                    <p>TOP</p>
                </a>
            </div>
        </div>
    );
}

export default SppBacktop;
