import SPP from '../../css/special-pack2.module.css';
import title_kokuken from '../../img/special-pack/title_kokuken.png';

const AirHeader = () => {
    return (
        <>
            <section className={SPP.specialFull}>
                <div className={`${SPP.pageTitle} ${SPP.specialHead} ${SPP.ARbg}`}>
                    <img src={title_kokuken} alt="特別割引！航空券往復日帰りパック" />
                </div>
                <div className={SPP.areaTitle}>
                    <p>羽田 &#8594; 新千歳・伊丹・福岡・那覇</p>
                </div>
            </section>

            <section className={`${SPP.specialFull} ${SPP.pageDescript}`}>
                <div className={SPP.sfMain}>
                    <p>観光にもビジネスにも!</p>
                    <p>航空券のご利用が超お得に!</p>
                </div>
                <div className={SPP.sfText}>
                    <p>羽田空港発、新千歳・伊丹・福岡・那覇空港着の航空券をご利用なら、ジョルダンMaaSパッケージがだんぜん便利！</p>
                    <p>気になるパックがありましたら「お問い合わせ」ボタン<span className={SPP.sub}>(※)</span>からお気軽にお問い合せください。「ジョルダントラベル」の旅行専門スタッフが、お客様のご要望に合わせた最適なプランをご提案します！</p>
                    <p><span className={SPP.sub}>(※) Googleフォームが開きますので、必要事項をご入力の上お問い合わせください。</span></p>
                </div>
            </section>
        </>
    );
}

export default AirHeader;