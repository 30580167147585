import { FC } from 'react';
import { Helmet } from 'react-helmet-async';

const MainHeadBlock: FC = () => {

    return(
        <Helmet>
            <meta
                name="Keywords"
                content="ジョルダントラベル,国内旅行,出張,パッケージ,出張パック,旅行相談,旅行窓口,ホテル,航空券,比較,ジョルダン,乗換案内"
            />
            <meta
                name="Description"
                content="最安値航空券も一発検索！旅行パッケージ作成・こだわり条件のご相談、旅行のプロにおまかせください。「ジョルダンMaaSパッケージ」なら飛行機・新幹線・特急・ホテル・食事もまとめてご予約可能です。"
            />
            <title>ジョルダントラベル</title>
            <meta property="og:locale" content="ja_JP" />
            <meta property="og:site_name" content="ジョルダントラベル" />
            <meta
                property="og:title"
                content="ジョルダントラベル | 旅行パッケージ作成"
            />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://travel.jorudan.co.jp" />
            <meta
                property="og:image"
                content="https://travel.jorudan.co.jp/ogimage.png"
            />
            <meta
                property="og:description"
                content="最安値航空券も一発検索！旅行パッケージ作成・こだわり条件のご相談、旅行のプロにおまかせください。「ジョルダンMaaSパッケージ」なら飛行機・新幹線・特急・ホテル・食事もまとめてご予約可能です。"
            />
            <meta
                property="article:publisher"
                content="https://www.facebook.com/JorudanTravel/"
            />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@JorudanTravel" />
        </Helmet>
    );
};

export default MainHeadBlock;