import { FC, useState, useEffect, ReactNode } from 'react';

import axis from 'axios';
import parse from 'html-react-parser';
import qs from 'qs';

import ShinkansenHeadBlock from '../../components/shinkansen/Helmet';
import HomeHeader from '../../components/shinkansen/HomeHeader';
import SPPHeader from '../../components/SPPHeader';
import SPPBackTop from '../../components/SPPBackTop';
import SPPFooter from '../../components/SPPFooter';

import SPP from '../../css/special-pack.module.css';

import { SpecialPackShinkansen } from '../../interfaces';

const Shinkansen: FC = () => {
    const [datas, setDatas] = useState([] as SpecialPackShinkansen[]);
    const [directions, setDierections] = useState([] as string[]);

    useEffect(() => {
        const getData = () => {
            const bufSampleTypes: SpecialPackShinkansen[] = [];
            const bufDirections: string[] = [];

            const apiUrl = `${process.env.REACT_APP_STRAPI_URL}/special-pack-shinkansens`;
            const p = {
                populate: 'special_pack_shinkansen_direction',
                filters: {
                    $or: [
                        {
                            Area: {
                                $eq: '東京'
                            }
                        },
                        {
                            Area: {
                                $eq: '東京/品川'
                            }
                        },
                    ],
                },
                sort: [
                    'SortOrder:asc'
                ]
            }

            const headers = {
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_STRAPI_KEY}`
                }
            };

            const today = Date.now();
            //console.log(today)
            
            axis.get(`${apiUrl}?${qs.stringify(p)}`, headers)
                .then((res) => {
                    console.log(res.data.data)
                    res.data.data.forEach((resData: any) => {
                        //console.log(resData);

                        const data: SpecialPackShinkansen = resData.attributes;

                        data.Direction = resData.attributes.special_pack_shinkansen_direction.data.attributes.name;

                        if(bufDirections.indexOf(data.Direction) < 0) {
                            bufDirections.push(data.Direction)
                        }

                        bufSampleTypes.push(data);
                    });
                
                    setDatas(bufSampleTypes);
                    setDierections(bufDirections);
                })
                .catch((error) => {
                    console.log(error);
            });
        };
    
        getData();
      }, []);
    
    return (
        <>
            <ShinkansenHeadBlock direction='home' />

            <SPPHeader />

            <HomeHeader />

            {
                <section className={SPP.homeGLink}>
                    <ul>
                        {
                            directions.map((val, i) => {
                                return (
                                    <li key={i}><a href={`#${i}`}>{val}</a></li>
                                );
                            })
                        }
                    </ul>
                </section>
            }

            {
                directions.map((v, i) => {
                    const targets = datas.filter(x => x.Direction === v);
                    
                    return (
                        <section key={i}>
                            <div className={SPP.homeAreaTitle}>{parse(v)}</div>
                            <div id={`${i}`}></div>

                            { createArticle(targets) }
                        </section>
                    )
                })
            }
            
            <SPPBackTop />

            <SPPFooter />
        </>
    );
}

function createArticle(targets: SpecialPackShinkansen[]): ReactNode {
    return targets.map((v, i) => {
        return (
            <article className={SPP.tableWrap} key={`${v.Area}_${i}`}>
                <div className={SPP.tableWrapTitle}>
                    <p>{v.Title}</p>
                </div>

                <div className={SPP.tableWrapContent}>

                    <table>
                        <thead>
                            <tr>
                                <th colSpan={2}>駅名</th>
                                <th>{parse(v.Label_Normal)}</th>
                                <th>{parse(v.Label_Special)}</th>
                                <th className={SPP.enOtokuG}>最大割引金額</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td rowSpan={2} className={SPP.areaName}>{v.Area}</td>
                                <td className={SPP.routeName}>片道</td>
                                <td className={SPP.en}>{v.Price_Normal_OneWay.toLocaleString()}</td>
                                <td></td>
                                <td className={SPP.enOtokuG}></td>
                            </tr>

                            <tr>
                                <td className={SPP.routeName}>往復</td>
                                <td className={SPP.en}>{v.Price_Normal_RoundTrip.toLocaleString()}</td>
                                <td className={`${SPP.en} ${SPP.pack}`}>{v.Price_Special_RoundTrip.toLocaleString()}</td>
                                <td className={`${SPP.enOtoku} ${SPP.enOtokuG}`}><p>{v.Discount_Normal_RoundTrip.toLocaleString()}</p></td>
                            </tr>

                            <tr className={SPP.toiawaseWrap}>
                                <td colSpan={6}>
                                    <div className={SPP.cautionWrap}>
                                        <p>
                                        {parse(v.Description)}
                                        </p>
                                    </div>
                                    <div>
                                        <p>{v.RequestArea}の<span>往復パックを利用する</span></p>
                                        <a href={`${v.Url}`} target="_blank" rel="noreferrer noopener" title="お問合せボタン">
                                        お問い合わせ</a>
                                    </div>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
            </article>
        );
    });
}

export default Shinkansen;
