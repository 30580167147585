import { FC } from 'react';
import { Helmet } from 'react-helmet-async';

type Props = {
    from?: string;
    direction: string;
}

const ShinkansenHeadBlock: FC<Props> = (props) => {
    const { from, direction } = props;

    const title = (() => {
        if(from) {
            return `ジョルダントラベル | お得な新幹線往復日帰りパック（${from}方面→${direction}）`;
        } else {
            return `ジョルダントラベル | お得な新幹線往復日帰りパック`;
        }
    })();

    return(
        <Helmet>
            <meta name="Keywords" content="ジョルダントラベル,国内旅行,新幹線,出張,パッケージ,出張パック,旅行相談,旅行窓口,ホテル,航空券,比較,ジョルダン,乗換案内" />
            <meta name="Description" content="お得な新幹線往復パックのご利用なら！旅行パッケージ作成・こだわり条件のご相談、旅行のプロにおまかせください。「ジョルダンMaaSパッケージ」なら飛行機・新幹線・特急・ホテル・食事もまとめてご予約可能です。" />
            <title>{title}</title>
            <meta property="og:locale" content="ja_JP" />
            <meta property="og:site_name" content="ジョルダントラベル" />
            <meta property="og:title" content={title} />
            <meta property="og:type" content="article" />
            <meta property="og:image" content="https://travel.jorudan.co.jp/ogimage.png" />
            <meta property="og:description" content="お得な新幹線往復パックのご利用なら！旅行パッケージ作成・こだわり条件のご相談、旅行のプロにおまかせください。「ジョルダンMaaSパッケージ」なら飛行機・新幹線・特急・ホテル・食事もまとめてご予約可能です。" />
            <meta property="article:publisher" content="https://www.facebook.com/JorudanTravel/" />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@JorudanTravel" />
        </Helmet>
    );
};

export default ShinkansenHeadBlock;
