import React from 'react';
import { FC, useState, useEffect, ReactNode } from 'react';
//import axis from 'axios';
import parse from 'html-react-parser';

import { AirHeadBlock } from '../../components/air/Helmet';
import SPPHeader from '../../components/SPPHeader';
import SPPBackTop from '../../components/SPPBackTop2';
import SPPFooter from '../../components/SPPFooter2';
import { SpecialPackAir } from '../../interfaces';
import AirHeader from '../../components/air/Header';

import SPP from '../../css/special-pack2.module.css';

type Props = {
    from: string;
}

const resDatas = [
    {
        attributes: {
            id: 1,
            Destination: 'haneda_shinchitose',
            Label_Normal: '通常料金',
            Label_Special: '弊社特別価格',
            Title: '新千歳空港 行き',
            Area: '羽田',
            Price_Normal: 39840,
            Price_Special: 20240,
            Discount: 19600,
            RequestArea: '新千歳空港行き',
            Description:'【注意事項】<br>※記載の割引運賃は1名様あたりの片道税込運賃となります。（空港使用料含みます）<br>※記載の割引運賃は11/1（通常期）のANA基準となっており、お客様のご希望日程や便により運賃は変動致します。<br>※記載の割引運賃はANAですが、JAL・SKY・ADO・SFJの取扱いもございます。<br>※お見積りにて承ります。<br>※チケット申込（決済完了まで）は2営業日前の弊社営業時間までとなります。<br>　それまでに決済が完了していない件につきましては、予約依頼後であってもキャンセルの扱いとなります。<br>　決済完了前のお取消しにつきましては、キャンセル料はかかりません。<br>※キャンセル料につきましては、決済後より発生いたします。<br>　見積り時にご案内致しますが、お手配の航空会社・券種（割引運賃）により変わります。<br>※チケットのご案内はメールでのご案内となり発送は致しませんので予めご了承下さい。',
            Url: 'https://docs.google.com/forms/d/e/1FAIpQLSfs1QUp6rhABviyyuwbZQ8Z8h1crqlUUILR-pyB1MyKrAWtvA/viewform?usp=pp_Url&entry.2132749945=%E7%BE%BD%E7%94%B0%E7%A9%BA%E6%B8%AF&entry.26624918=%E6%96%B0%E5%8D%83%E6%AD%B3%E7%A9%BA%E6%B8%AF',
        }
    },
    {
        attributes: {
            id: 2,
            Destination: 'haneda_itami',
            Label_Normal: '通常料金',
            Label_Special: '弊社特別価格',
            Title: '伊丹空港 行き',
            Area: '羽田',
            Price_Normal: 27110,
            Price_Special: 13910,
            Discount: 13200,
            RequestArea: '伊丹空港行き',
            Description:'【注意事項】<br>※記載の割引運賃は1名様あたりの片道税込運賃となります。（空港使用料含みます）<br>※記載の割引運賃は11/1（通常期）のANA基準となっており、お客様のご希望日程や便により運賃は変動致します。<br>※記載の割引運賃はANAですが、JAL・SKY・ADO・SFJの取扱いもございます。<br>※お見積りにて承ります。<br>※チケット申込（決済完了まで）は2営業日前の弊社営業時間までとなります。<br>　それまでに決済が完了していない件につきましては、予約依頼後であってもキャンセルの扱いとなります。<br>　決済完了前のお取消しにつきましては、キャンセル料はかかりません。<br>※キャンセル料につきましては、決済後より発生いたします。<br>　見積り時にご案内致しますが、お手配の航空会社・券種（割引運賃）により変わります。<br>※チケットのご案内はメールでのご案内となり発送は致しませんので予めご了承下さい。',
            Url: 'https://docs.google.com/forms/d/e/1FAIpQLSfs1QUp6rhABviyyuwbZQ8Z8h1crqlUUILR-pyB1MyKrAWtvA/viewform?usp=pp_Url&entry.2132749945=%E7%BE%BD%E7%94%B0%E7%A9%BA%E6%B8%AF&entry.26624918=%E4%BC%8A%E4%B8%B9%E7%A9%BA%E6%B8%AF',
        }
    },
    {
        attributes: {
            id: 3,
            Destination: 'haneda_fukuoka',
            Label_Normal: '通常料金',
            Label_Special: '弊社特別価格',
            Title: '福岡空港 行き',
            Area: '羽田',
            Price_Normal: 42200,
            Price_Special: 20350,
            Discount: 21850,
            RequestArea: '福岡空港行き',
            Description: '【注意事項】<br>※記載の割引運賃は1名様あたりの片道税込運賃となります。（空港使用料含みます）<br>※記載の割引運賃は11/1（通常期）のANA基準となっており、お客様のご希望日程や便により運賃は変動致します。<br>※記載の割引運賃はANAですが、JAL・SKY・ADO・SFJの取扱いもございます。<br>※お見積りにて承ります。<br>※チケット申込（決済完了まで）は2営業日前の弊社営業時間までとなります。<br>　それまでに決済が完了していない件につきましては、予約依頼後であってもキャンセルの扱いとなります。<br>　決済完了前のお取消しにつきましては、キャンセル料はかかりません。<br>※キャンセル料につきましては、決済後より発生いたします。<br>　見積り時にご案内致しますが、お手配の航空会社・券種（割引運賃）により変わります。<br>※チケットのご案内はメールでのご案内となり発送は致しませんので予めご了承下さい。',
            Url: 'https://docs.google.com/forms/d/e/1FAIpQLSfs1QUp6rhABviyyuwbZQ8Z8h1crqlUUILR-pyB1MyKrAWtvA/viewform?usp=pp_Url&entry.2132749945=%E7%BE%BD%E7%94%B0%E7%A9%BA%E6%B8%AF&entry.26624918=%E7%A6%8F%E5%B2%A1%E7%A9%BA%E6%B8%AF',
        }
    },
    {
        attributes: {
            id: 4,
            Destination: 'haneda_naha',
            Label_Normal: '通常料金',
            Label_Special: '弊社特別価格',
            Title: '那覇空港 行き',
            Area: '羽田',
            Price_Normal: 48310,
            Price_Special: 24460,
            Discount: 23850,
            RequestArea: '那覇空港行き',
            Description:'【注意事項】<br>※記載の割引運賃は1名様あたりの片道税込運賃となります。（空港使用料含みます）<br>※記載の割引運賃は11/1（通常期）のANA基準となっており、お客様のご希望日程や便により運賃は変動致します。<br>※記載の割引運賃はANAですが、JAL・SKY・ADO・SFJの取扱いもございます。<br>※お見積りにて承ります。<br>※チケット申込（決済完了まで）は2営業日前の弊社営業時間までとなります。<br>　それまでに決済が完了していない件につきましては、予約依頼後であってもキャンセルの扱いとなります。<br>　決済完了前のお取消しにつきましては、キャンセル料はかかりません。<br>※キャンセル料につきましては、決済後より発生いたします。<br>　見積り時にご案内致しますが、お手配の航空会社・券種（割引運賃）により変わります。<br>※チケットのご案内はメールでのご案内となり発送は致しませんので予めご了承下さい。',
            Url: 'https://docs.google.com/forms/d/e/1FAIpQLSfs1QUp6rhABviyyuwbZQ8Z8h1crqlUUILR-pyB1MyKrAWtvA/viewform?usp=pp_Url&entry.2132749945=%E7%BE%BD%E7%94%B0%E7%A9%BA%E6%B8%AF&entry.26624918=%E9%82%A3%E8%A6%87%E7%A9%BA%E6%B8%AF',
        }
    },
];

const Air: FC<Props> = (props) => {
    //const { from } = props;
    const [datas, setDatas] = useState([] as SpecialPackAir[]);

    useEffect(() => {
        const buffer: SpecialPackAir[] = [];

        const getData = () => {
            resDatas.forEach((resData: any) => {
                buffer.push(resData.attributes);
            });
            
            setDatas(buffer);
        };
    
        getData();
      }, []);
    
    return (
        <>
            <AirHeadBlock direction='home' />

            <SPPHeader />

            <AirHeader />

            <section>
            {
                datas.map((v, _) => {
                    return (
                        <React.Fragment key={v.Destination}>
                            { createArticle(v) }
                        </React.Fragment>
                    )
                })
            }
            </section>
            
            <SPPBackTop />

            <SPPFooter />
        </>
    );
}

function createArticle(data: SpecialPackAir): ReactNode {
    console.log(data)
    return (
        <>
            { /* <a id="shinchitose"></a> */ }
            <article className={SPP.tableWrap} id={data.Destination}>
                <div className={SPP.tableWrapTitle}>
                    <p>{data.Title}</p>
                </div>
                
                <div className={SPP.tableWrapContent}>
                
                    <table>
                        <thead>
                            <tr>
                                <th colSpan={2}>出発空港</th>
                                <th>{parse(data.Label_Normal)}</th>
                                <th>{parse(data.Label_Special)}</th>
                                <th className={SPP.enOtokuG}>お得額</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td className={SPP.areaName}>{data.Area}</td>
                                <td className={SPP.routeName}>普通席</td>
                                <td className={SPP.en}>{data.Price_Normal.toLocaleString()}</td>
                                <td className={SPP.en}>{data.Price_Special.toLocaleString()}</td>
                            
                                <td className={`${SPP.enOtoku} ${SPP.enOtokuG}`}><p>{data.Discount.toLocaleString()}</p></td>
                            </tr>
                            
                            <tr className={SPP.toiawaseWrap}>
                                <td colSpan={6}>
                                    <div className={SPP.cautionWrap}>
                                        <p>
                                            {parse(data.Description)}
                                        </p>
                                    </div>
                                    <div>
                                        <p>{data.RequestArea}の<span>お得な航空券を利用する</span></p>
                                        <a href={data.Url} target="_blank" rel="noreferrer noopener" title="Googleフォームから必要事項をご入力の上、お問い合わせください。">
                                            お問い合わせ
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </article>
        </>
    );
}

export default Air;
